import React, { Component } from "react";
import ForgotPassword from "./ForgotPassword";
class ForgotPasswords extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ForgotPassword />;
  }
}

export default ForgotPasswords;
